$(function () {

    //导航条下拉菜单
    DownShow(".nav>li", ".nav_down", "active");

    //云存证tab切换
    TagShow(".product-list ul li", ".mess_show", "active");

    //可采用性校验
    $(".second .nav_down ul li:eq(1)").click(function () {
        $(this).attr("href", "/depositcloud/");
        $(".product-list ul li:eq(1)").addClass("active").siblings().removeClass("active");
        $(".mess_show:eq(1)").removeClass("hide").siblings().addClass("hide");
    });

    $(".uid").data("uid");

    //首页合作伙伴
    var oneWidth = - $(".wrap-list").width(),//单个宽度
        len = $(".wrap-list").length,//个数
        allWidth = oneWidth * len,//总长度
        left = 0;
    $(".arightbtn").click(function () {
        left = left - 268;
        $(this).siblings(".aleftbtn").removeClass("agrayleft");
        if (left <= oneWidth - 22) {
            left = oneWidth - 22;
            $(this).addClass("agrayright");
        }
        $(".cooperative-list").css({
            "left": left,
            "transition": 0.5 + 's'
        });
    });
    $(".aleftbtn").click(function () {
        left = left + 268;
        $(this).siblings(".arightbtn").removeClass("agrayright");
        if (left >= 0) {
            left = 0;
            $(this).addClass("agrayleft");
        }
        $(".cooperative-list").css({
            "left": left,
            "transition": 0.5 + 's'
        });
    });

    //回到顶部goback
    var offset = 600,
        scroll_time = 800;
    $(window).scroll(function () {
        $(this).scrollTop() > offset ? $(".other").fadeIn(500) : $(".other").fadeOut(500);
    });

    $(".backtop").click(function (event) {
        event.preventDefault();
        $("body,html").animate({ scrollTop: 0 }, scroll_time);
    });

    $(".backtop").hover(function () {
        $(this).css("opacity", "1");
    }, function () {
        $(this).css("opacity", "0.8");
    });

    /* 关于我们的点击交互 */
    $(".sub_sidebar_list li").each(function (index, item) {
        var top = -1;
        $(this).click(function () {
            $(this).addClass("current").siblings().removeClass("current");

            switch (index) {
                case 0: top = -1;
                    break;

                case 1: top = 44;
                    break;

                case 2: top = 89;
                    break;

                case 3: top = 134;
                    break;

                case 4: top = 179;
                    break;
            }
            $(".cover_layer").css("top", top + 'px');
        });
    });



    /* 国内学术会议发布交互 start  */
    $("#academic-title").on("click", "li", function () {
        $(this).addClass("cur").siblings().removeClass("cur");
    });

    //国内学术会议详情页交互
    $(".ticketType .section1 li").on("click", function () {
        var index = $(this).index();
        $(this).addClass("active").siblings().removeClass("active");
        $(".section1 .triangle_border_left").addClass("hide").eq(index).removeClass("hide");
        $(".detail-introduce").eq(index).removeClass("hide").siblings(".detail-introduce").addClass("hide");
    });

    //优化关于我们的锚点跳转
    /*        var items = $(".aboutus").find("dd");
            items.each(function(){
                var href = $(this).children("a").attr("href");
                $(this).children("a").attr("href",href + $(this).children("a").data("anchor"));
            });*/
    //点击视频付费
    $(".ispay").click(function () {
        var This = this;
        ispay(This);
    });

    //视频点击付费(支付宝)
    $(".alipay").click(function () {
        videoAlipay();
    });

    //点击立即学习
    $(".study-btn").click(function () {
        var This = this;
        studyNow(This);
    })
});

//点击判断视频是否付费
/*function ispay(This){
    var uid = $("#new-video-title").attr("data-uid"),
        vip = $("#new-video-title").attr("data-vip").toLowerCase(),
        bfHref = window.location.href,
        href = bfHref.indexOf("#") === -1 ? bfHref:bfHref.replace("#",''),
        courseId = $(This).find(".video-h").attr("data-course-id"),
        hrefVideo = href + 'video_learning/?courseid='+ courseId,
        hrefPaid = href + 'order_details/' ,
        coverUrl = $(This).find(".new-video-item-img").attr("src"),
        title = $(This).find(".video_title").text(),
        price = $(This).find(".courses-price").text(),
        teaname = $(This).find(".video-h").attr("data-name");
    //判断用户是否注册
    if(uid){
        if(vip ==='true'){
            //VIP用户权限观看视频
            window.location.href = hrefVideo;
        }else{
            //普通付费用户让其观看
            xfzajax.post({
                'url':'/college/is_paid/'+ courseId +'/',
                'data':{ uid:uid },
                'success':function(result){
                    var isPaid = result.data.is_paid;
                    if(isPaid){
                        //已付费直接看视频
                        window.location.href = hrefVideo;
                    }else{
                        //未付费
                        window.location.href = hrefPaid +'?courseid='+ courseId +'&title='+ title+'&teaname='+ teaname+'&price='+price+'&coverUrl='+coverUrl;
                    }
                },
                'error':function(error){
                    console.log(error);
                }
            });
        }
    }else{
        $(".mask-wrapper").show();
    }
}*/

//进行付费(支付宝)
function videoAlipay() {
    var form = $(".alipay-form"),
        uid = $(".userid").attr("data-id"),
        courseId = GetRequests().courseid,
        money = parseFloat($("#pay-num1").text()),
        my_input1 = $('<input type="text" name="user_id" />'),
        my_input2 = $('<input type="number" name="courses_id" />'),
        my_input3 = $('<input type="number" name="money" />');
    my_input1.attr('value', uid);
    my_input2.attr('value', courseId);
    my_input3.attr('value', money);

    form.attr("action", '/college/ali_pay/');
    form.append(my_input1);
    form.append(my_input2);
    form.append(my_input3);
    $(".alipay-form input").css('display', 'none');
    form.submit();
}
/* 产业动态轮播图 start */
var src = $(".my-picture").eq(0).find("img").attr("src");
function setBanner() {
    var first = $('.news-scroll-list').find('.my-picture').first(),
        last = $('.news-scroll-list').find('.my-picture').last(),
        len = $(".my-picture").length,
        i = 0,
        oBtn = true,
        width = $(".my-picture").width(),
        timer = null;
    $('.news-scroll-list').prepend(last.clone());
    $('.news-scroll-list').append(first.clone());
    $('.news-scroll-list').width($(".my-picture").length * width);
    $('.news-scroll-list').css('left', -width * 1);

    $('.btns>a.btnl').click(function () { if (oBtn) { next(); } });
    $('.btns>a.btnr').click(function () { if (oBtn) { prev(); } });
    $('.autos').find('span').click(function () {
        i = $(this).index();
        $('.news-scroll-list').animate({
            left: -width * (i + 1)
        }, 800, function () {
            $('.autos').find('span').attr('class', '').css("width", "6px");
            $('.autos').find('span').eq(i).attr('class', 'cur').css("width", "15px");
        });
    });
    //添加定时触发和hover关闭clearInterval
    a();
    function a() { timer = setInterval(function () { $('.btns>a.btnr').trigger("click"); }, 3000); }
    $(".news-scroll").hover(function () { $(".btns").css("display", "block"); clearInterval(timer); }, function () { $(".btns").css("display", "none"); a(); });

    //点击上一张按钮
    function prev() {
        oBtn = false;
        $('.news-scroll-list').animate({
            left: '-=' + width
        }, 800, function () {
            i++;
            if (i == len) { $('.news-scroll-list').css({ 'left': -width }); i = 0; }
            $('.autos').find('span').attr('class', '').css("width", "6px");
            $('.autos').find('span').eq(i).attr('class', 'cur').css("width", "15px");
            oBtn = true;
        });
    }
    //点击上一张按钮
    function next() {
        oBtn = false;
        $('.news-scroll-list').animate({
            left: '+=' + width
        }, 800, function () {
            if (i == 0) { i = len; $('.news-scroll-list').css('left', -width * len) }
            i--;
            $('.autos').find('span').attr('class', '').css("width", "6px");
            $('.autos').find('span').eq(i).attr('class', 'cur').css("width", "15px");
            oBtn = true;
        });
    }
}
/* 产业动态轮播图 end */

//点击立即学习
function studyNow(This) {
    var uid = $(".usid").attr("data-uid"),
        href = window.location.href,
        hrefbase = href.split('more_video/')[0],
        hrefPaid = hrefbase + 'order_details/',
        courseId = $(This).siblings(".class-item-right-header").find(".class-item-header-left").attr("data-id"),
        hrefVideo = hrefbase + 'video_learning/?courseid=' + courseId,
        title = $(This).siblings(".class-item-right-header").find(".class-item-header-center").text(),
        price = $(This).siblings(".class-item-right-footer").find(".price-num").text(),
        teaname = $(This).siblings(".class-item-right-body").find(".teaname").text(),
        coverUrl = $(This).parent(".class-item-right").siblings(".class-item-left").find(".class-item-img").attr("src");
    if (uid) {
        xfzajax.post({
            'url': '/college/is_paid/' + courseId + '/',
            'data': { uid: uid },
            'success': function (result) {
                /* var isPaid = result.data.is_paid;*/
                var isPaid = true;
                if (isPaid) {
                    //已付费直接看视频
                    window.location.href = hrefVideo;
                } else {
                    //未付费
                    window.location.href = hrefPaid + '?courseid=' + courseId + '&title=' + title + '&teaname=' + teaname + '&price=' + price + '&coverUrl=' + coverUrl;
                }
            },
            'error': function (error) {
                console.log(error);
            }
        });
    } else {
        $(".mask-wrapper").show();
    }
}

/*TagShow封装*/
function TagShow(obj1, obj2, cl) {
    var id;
    $(obj1).click(function () {
        id = $(obj1).index($(this));
        $(obj1).removeClass(cl);
        $(this).addClass(cl);
        $(obj2).addClass("hide");
        $(obj2).eq(id).removeClass("hide");
        if ($(this).text() == "我的学习" || $(this).text() == "我的收藏") {
            $(obj2).eq(id).find(".header_box1").click();
        }
    });
    $(obj2 + ":first").removeClass("hide");
}

/*导航条下拉菜单*/
DownShow(".nav>li", ".nav_down", "active");
function DownShow(obj1, obj2, cl) {
    var index, id, timer, divcreate, parent, navdowna;
    divcreate = false;
    $(obj1).hover(
        function () {
            id = $(obj1).index(this);
            navdowna = $(obj1).find(".nav_down a");
            $(obj1).removeClass(cl);
            $(this).addClass(cl);
            navdowna.delay(300).css("color", "#808080");
            $('.' + cl).find(".nav_down a").delay(300).css("color", "#f2f2f2");
            $(obj1).find(".nav_down a");
            navdowna.hover(function () {
                $(this).css("color", "#51e3fe");
            },
                function () {
                    $(this).css("color", "#f2f2f2");
                    currentcolor = '';
                });
            hidecur();
            $(obj2).stop(true, true).delay(500).slideDown(100, function () { });
            divcreate = true;
            index = id;
        }, function () {
            id = $(obj1).index(this);
            divcreate = false;
            hidecur();
            $(obj1).removeClass(cl);
        });

    //控制蒙版的动画
    parent = $(obj1).parent();
    var background = '';
    parent.hover(function () {
        background = $(".header").css("background");
        $(".nav-list").stop(true, true).animate({ "height": "220px" }, "linear").find(".hr").delay(300).css("display", "block");
        $(".header").css({ "background": "#000" });
    }, function () {
        $(".nav-list").stop(true, true).animate({ "height": "0px" }, "linear").find(".hr").delay(300).css("display", "none");
        $(".header").css({ "background": background });
    });

    $(obj2).hover(function () { divcreate = true; hidecur(); }, function () { id = $(obj2).index(this); divcreate = false; hidecur(); });
    function hidecur() {
        clearTimeout(timer);
        if (index != id) { $(obj2).stop(true, true).delay(50).fadeOut("fast", function () { }); }
        timer = setTimeout(function () {
            if (!divcreate) {
                $(obj2).stop(true, true).delay(50).fadeOut("fast", function () { });
            }
        }, 100);
    }
}

/*关于我们页面的scroll交互*/
function locationNav() {
    var t = "",
        e = $(document).height() - $(window).height(),
        a = $(".sub_sidebar_list"),
        o = [];
    $(".sub_sidebar_list a").each(function () {
        o.push($(this).attr("href"))
    }),
        $(window).scroll(function () {
            for (var n = $(document).scrollTop(), i = 0; i < o.length; i++) {
                var s = o[i];
                n > $(s).offset().top - 300 && (t = s), $(window).scrollTop() == e && (t = o[o.length - 1])
            }
            var r = a.find(".current");
            t && r.find("a").attr("href") != t && (r.removeClass("current"), a.find("[href=" + t + "]").parent().addClass("current"));
            var c = a.find("[href=" + t + "]").parent().position().top - 1;
            $(".cover_layer").stop().animate({
                top: c
            }, 150)
        }), $(".sub_sidebar_list a").on("click", function () {
            event && event.preventDefault ? event.preventDefault() : window.event.returnValue = !1;
            var t = $(this).attr("href"),
                e = $(t).offset().top - 60;
            $("html, body").stop().animate({
                scrollTop: e
            }, 400)
        })
}

//首页双重选项卡交互
function newsTab(tabLi, box, cla, parent) {
    $(tabLi).hover(function () {
        $(this).siblings(tabLi).removeClass(cla); //先将所有同级去掉active
        $(this).parents(parent).siblings(box).addClass("hide");   //所有同级box隐藏
        $(this).addClass(cla);   //当前li显示
        $(this).parents(parent).siblings(box).eq($(this).index()).removeClass("hide");
    }, function () { });
}

/* 设置缓存和获取缓存 start */
var uzStorage = function () {
    var ls = window.sessionStorage || (window.UserDataStorage && new UserDataStorage()) || new cookieStorage();
    return ls;
};
//定义全局变量u
var u = {};
//设置缓存
u.setStorage = function (key, value) {
    var v = value;
    if (typeof v == 'object') {
        v = JSON.stringify(v);
        v = 'obj-' + v;
    } else {
        v = 'str-' + v;
    }
    var ls = uzStorage();
    if (ls) {
        ls.setItem(key, v);
    }
};
//获取缓存
u.getStorage = function (key) {
    var ls = uzStorage();
    if (ls) {
        var v = ls.getItem(key);
        if (!v) {
            return;
        }
        if (v.indexOf('obj-') === 0) {
            v = v.slice(4);
            return JSON.parse(v);
        } else if (v.indexOf('str-') === 0) {
            return v.slice(4);
        }
    }
};

/* 设置缓存和获取缓存 end */

//首页渲染数据
function renderNews(newsdata) {
    var aca_front = newsdata.academic_front,
        app_scene = newsdata.application_scene,
        laws_reg = newsdata.laws_regulations,
        pro_dynomic = newsdata.product_dynomic,
        tec_dev = newsdata.technological_development;

    //产业动态
    for (var i = 0; i < pro_dynomic.length; i++) {
        var pubTime = pro_dynomic[i].pub_time.substring(0, 10),
            value = pro_dynomic[i].title.substring(0, 18) + '...';
        var content = pro_dynomic[i].description;
        if (i < 5) {
            $(".pro_dynomic .forensics-item").find(".content-title .news-title ").eq(i).text(value);
            $(".pro_dynomic .forensics-item").find(".content-title .date").eq(i).text(pubTime);
            $(".pro_dynomic").find(".forensics-content").eq(i).find(".left-img img").attr("src", pro_dynomic[i].thumbnail);
            $(".pro_dynomic").find(".forensics-content").eq(i).find(".news-content-box h3").text(pro_dynomic[i].title);
            $(".pro_dynomic").find(".forensics-content").eq(i).find(".news-content-box .news-time").text(pubTime);
            //$(".aca_front").find(".forensics-content").eq(i).find(".news-content-box .news-content").text(pubTime);
            $(".pro_dynomic").find(".forensics-content").eq(i).find(".news-content-box .news-content").text(content);
            $(".pro_dynomic").find(".forensics-content").eq(i).find(".news-content-box .href a").attr('href', '/news_forensics/news_detail/' + pro_dynomic[i].id + '/');
        }
    }

    //学术前沿
    for (var j = 0; j < aca_front.length; j++) {
        var pubTime = aca_front[j].pub_time.substring(0, 10),
            value = aca_front[j].title.substring(0, 18) + '...';
        if (j < 5) {
            $(".aca_front .forensics-item").find(".content-title .news-title ").eq(j).text(value);
            $(".aca_front .forensics-item").find(".content-title .date").eq(j).text(pubTime);
            $(".aca_front").find(".forensics-content").eq(j).find(".left-img img").attr("src", aca_front[j].thumbnail);
            $(".aca_front").find(".forensics-content").eq(j).find(".news-content-box h3").text(aca_front[j].title);
            $(".aca_front").find(".forensics-content").eq(j).find(".news-content-box .news-time").text(pubTime);
            $(".aca_front").find(".forensics-content").eq(j).find(".news-content-box .news-content").text(aca_front[j].description);
            //$(".aca_front").find(".forensics-content").eq(j).find(".news-content-box .news-content").text(pubTime);
            $(".aca_front").find(".forensics-content").eq(j).find(".news-content-box .href a").attr('href', '/news_forensics/news_detail/' + aca_front[j].id + '/');
        }
    }

    //法律法规
    for (var k = 0; k < laws_reg.length; k++) {
        var pubTime = laws_reg[k].pub_time.substring(0, 10),
            value = laws_reg[k].title.substring(0, 18) + '...';
        if (k < 5) {
            $(".laws_reg .forensics-item").find(".content-title .news-title ").eq(k).text(value);
            $(".laws_reg .forensics-item").find(".content-title .date").eq(k).text(pubTime);
            $(".laws_reg").find(".forensics-content").eq(k).find(".left-img img").attr("src", laws_reg[k].thumbnail);
            $(".laws_reg").find(".forensics-content").eq(k).find(".news-content-box h3").text(laws_reg[k].title);
            $(".laws_reg").find(".forensics-content").eq(k).find(".news-content-box .news-time").text(pubTime);
            $(".laws_reg").find(".forensics-content").eq(k).find(".news-content-box .news-content").text(laws_reg[k].description);
            //$(".aca_front").find(".forensics-content").eq(k).find(".news-content-box .news-content").text(pubTime);
            $(".laws_reg").find(".forensics-content").eq(k).find(".news-content-box .href a").attr('href', '/news_forensics/news_detail/' + laws_reg[k].id + '/');
        }
    }

    //应用前景
    for (var l = 0; l < tec_dev.length; l++) {
        var pubTime = tec_dev[l].pub_time.substring(0, 10),
            value = tec_dev[l].title.substring(0, 18) + '...';
        if (l < 5) {
            $(".tec_dev .forensics-item").find(".content-title .news-title ").eq(l).text(value);
            $(".tec_dev .forensics-item").find(".content-title .date").eq(l).text(pubTime);
            $(".tec_dev").find(".forensics-content").eq(l).find(".left-img img").attr("src", tec_dev[l].thumbnail);
            $(".tec_dev").find(".forensics-content").eq(l).find(".news-content-box h3").text(tec_dev[l].title);
            $(".tec_dev").find(".forensics-content").eq(l).find(".news-content-box .news-time").text(pubTime);
            $(".tec_dev").find(".forensics-content").eq(l).find(".news-content-box .news-content").text(tec_dev[l].description);
            //$(".tec_dev").find(".forensics-content").eq(l).find(".news-content-box .news-content").text(pubTime);
            $(".tec_dev").find(".forensics-content").eq(l).find(".news-content-box .href a").attr('href', '/news_forensics/news_detail/' + tec_dev[l].id + '/');
        }
    }

    //技术发展
    for (var m = 0; m < app_scene.length; m++) {
        var pubTime = app_scene[m].pub_time.substring(0, 10),
            value = app_scene[m].title.substring(0, 18) + '...';
        if (m < 5) {
            $(".app_scene .forensics-item").find(".content-title .news-title ").eq(m).text(value);
            $(".app_scene .forensics-item").find(".content-title .date").eq(m).text(pubTime);
            $(".app_scene").find(".forensics-content").eq(m).find(".left-img img").attr("src", app_scene[m].thumbnail);
            $(".app_scene").find(".forensics-content").eq(m).find(".news-content-box h3").text(app_scene[m].title);
            $(".app_scene").find(".forensics-content").eq(m).find(".news-content-box .news-time").text(pubTime);
            $(".app_scene").find(".forensics-content").eq(m).find(".news-content-box .news-content").text(app_scene[m].description);
            //$(".aca_front").find(".forensics-content").eq(m).find(".news-content-box .news-content").text(pubTime);
            $(".app_scene").find(".forensics-content").eq(m).find(".news-content-box .href a").attr('href', '/news_forensics/news_detail/' + app_scene[m].id + '/');
        }
    }
}

//国内/国际学术会议二级页
function renderAcademic(url) {
    xfzajax.get({
        'url': url,
        'data': '',
        "success": function (result) {
            if (result.code == "200") {
                var resultData = result.data,
                    interConf = resultData.integrate_sort,
                    comprehensive_sort = resultData.comprehensive_sort,
                    academicData = [resultData.comprehensive_sort, resultData.lastest, resultData.hot, resultData.finished, resultData.processing, resultData.no_start];
                //国际学术会议数据
                renderDom(comprehensive_sort); //宣言综合
                $(".academic-title li").find("a").click(function () {
                    var index = $(this).data("value");
                    renderDom(academicData[index]);
                });
                //二级国内/国际会议首页banner
                for (var i = 0; i < interConf.length; i++) {
                    var src = interConf[i].bannar;
                    var url = '/academic/' + interConf[i].id + '/';
                    // if (i < 4) {
                    //     $(".my-picture").eq(i).find("a img").attr("src", src);
                    //     $(".my-picture").eq(i).find("a").attr("href", url);
                    // }
                    if (i < 3) {
                        var bannerDiv = $('<div class="my-picture img-do"><a href="' + url + '"><img src="' + src + '"><span class="shadow linear-box"><em class="linear-bg"></em><span></span></span></a></div>');
                        $(".news-scroll-list").append(bannerDiv);
                        var autosDiv = $('<span data-id="' + i + '" class=""><a></a></span>')
                        $(".autos").append(autosDiv);
                    }
                }

                if (interConf.length > 1) {
                    middleAutos = parseInt($(".autos").find("span").length / 2);
                    $(".autos").find("span").eq(middleAutos).addClass("cur");
                    $(".autos").find("span").eq(middleAutos).css("width", "15px");
                    console.log(middleAutos)
                    setBanner(); // 启动轮播
                }

            }
        }
    });
    //渲染学术会议二级页dom
    function renderDom(data) {
        if (Array.isArray(data)) {
            var interConfStr = '';
            $("#load_news").find("li").remove();
            if (data.length) {
                for (var j = 0; j < data.length; j++) {
                    var href = '/academic/' + data[j].id + '/?city=' + data[j].city,
                        len = data[j].title.length,
                        str = len > 30 ? data[j].title.substring(0, 32) + '......' : data[j].title;
                    interConfStr += '<li class="academic-item acdemic-item-small"><a href ="' + href + '"><img src="' + data[j].picture + '" alt="' + data[j].title + '"/><div class="item-info"><p><span class="fl time-info">' + data[j].begin_time.substring(0, 19).replace(/T/, ' ') + '</span><span class="fr address-info">' + data[j].city + '</span></p><br><div class="item-title" title="' + data[j].title + '">' + str + '</div><span class="visited-num fr"><i></i>' + data[j].visit_number + '</span></div></a></li>';
                }
            } else {
                interConfStr = '<li><div style="height:200px;width:100%;"></div></li>';
            }
            $("#load_news").prepend(interConfStr);
            // $("#load_news li").each(function () {
            //     if ($(this).index() % 3 == 2) {
            //         $(this).addClass("nth2");
            //     }
            // });
        }
    }
}

//论文下载加载数据
function renderpaper(paperdata) {
    $(".paper-data").find("tr").remove();
    var paperStr = '';
    if (Array.isArray(paperdata)) {
        if (!paperdata.length) {
            paperStr = '<tr><td colspan="6" style="text-align:center;">没找到匹配的结果！</td></tr>';
        } else {
            for (var i = 0; i < paperdata.length; i++) {
                paperStr += '<tr><td width="5%;">' + (i + 1) + '</td><td width="35%">' + paperdata[i].name + '</td><td width="11.5%" class="fontGray">' + paperdata[i].author + '</td><td width="18.5%" class="fontGray">' + paperdata[i].source + '</td><td width="14%" class="fontGray">' + paperdata[i].pub_time.substring(0, 10) + '</td><td width="10%">' + paperdata[i].download_time + '<a href="' + paperdata[i].file_path + '" class="downloadIcon fr mt20 mr20" download=""></a></td><td class="paper-id hide">' + paperdata[i].id + '</td></tr>';
            }
        }
        $(".paper-data").append(paperStr);
        paperStr = '';
    }
}

//获取值方法
function GetRequests() {
    var url = decodeURI(decodeURI(location.search)); //获取url中"?"符后的字串，使用两次decodeRUI解码
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1),
            strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
}

//判断是PC浏览器还是移动端浏览器
function checkUserAgentcom() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    var flag = true;
    for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }
    }
    return flag;
}


